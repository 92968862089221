<template>
    <Card title="Services">
        <form class="row g-3" @submit.prevent>
            <div class="col-12">
                <h3 class="float-start">{{ isEdit ? 'UPDATE' : 'NEW' }} SERVICE</h3>
                <div class="float-end">
                    <button type="button" class="btn btn-outline-secondary btn-sm m-1" @click="back">BACK</button>
                    <button type="button" class="btn btn-outline-danger btn-sm m-1" @click="remove" v-if="isEdit">DELETE</button>
                    <button type="button" class="btn btn-outline-success btn-sm m-1" @click="handleSubmit">SAVE</button>
                </div>
                <!-- :class="{'is-invalid': !$v.newItem.title.requred && $v.newItem.title.$error}" -->
            </div>
            <div class="col-12">
                <label for="title" class="form-label">Title</label>
                <input type="text" class="form-control" id="title" v-model="newItem.title">
            </div>
            <div class="col-12">
                <label for="icon" class="form-label">Fa Icon</label>
                <input type="text" class="form-control" id="icon" v-model="newItem.fa_icon">
            </div>
            <div class="col-12">
                <label for="text" class="form-label">Text</label>
                <textarea class="form-control" id="text" rows="5" v-model="newItem.text"></textarea>
            </div>
        </form>
    </Card>
</template>

<script>
import firebase from '../../../firebase'
import { required } from 'vuelidate/lib/validators'
import Card from '@/components/admin/Card.vue'
export default {
    components: { Card },
    props: [
        'item'
    ],
    data() {
        return {
            newItem: {
                title: '',
                fa_icon: '',
                text: ''
            },
            isEdit: false
        }
    },
    validation: {
        newItem: {
            title: { required },
            fa_icon: { required },
            text: { required }
        }
    },
    methods: {
        handleSubmit() {
            if(this.isEdit) {
                firebase.firestore().collection('services').doc(this.item.id).set(this.newItem).then(() => this.back()).catch(err => console.error(err))
            } else {
                firebase.firestore().collection('services').add(this.newItem).then(({ id }) => /* console.log(id) */ this.back()).catch(err => console.error(err))
            }
        },
        remove() {
            firebase.firestore().collection('services').doc(this.item.id).delete().then(() => this.back()).catch(err => console.error(err))
        },
        back() {
            this.$router.push({ name: 'AdminServicesList' })
        }
    },
    mounted() {
        if(this.item) {
            this.isEdit = true
            this.newItem = { ...this.item }
            delete this.newItem.id
        }
    }
}
</script>

<style scoped>
.form-control:focus {
    box-shadow: none;
}

textarea {
    resize: none;
}
</style>